.pagination{
    margin-top: 14px;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-size: 100%;
    font-family: inherit;
}

.pagination li {
    display: inline;
    color: #676767;
    padding: 0 10px;
    margin-right: 5px;
    background: #ebebeb;
    line-height: 25px;
    font-size: 13px;
}

.disable {
    background: rgb(70, 130, 180);
    color: #fff;
    text-decoration: none;
    display: block;
    float: left;
    padding: 0 10px;
    margin-right: 5px;
    background: #ebebeb;
    line-height: 25px;
    font-size: 13px;
}
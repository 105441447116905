/*
Theme Name: SmartMag
Theme URI: http://theme-sphere.com
Version: 2.5
Description: Designed by <a href="http://theme-sphere.com">ThemeSphere</a>
Author: ThemeSphere
Author URI: http://theme-sphere.com
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
	Copyright: (c) 2014 ThemeSphere.
*/

/*

1. =Resets & Default Elements
 1.1. =Typography
 1.2. =Other Elements
 1.3. =Main Helpers

2. =Global Structure
 2.1. =Grid
 2.2. =Header
 2.3. =Navigation
 2.4. =Featured & Slider

3. =Components & Common Classes

4. =Content
 4.1. =Main Page Blocks
 4.2. =Single Post
 4.3. =Single & Other Pages
 4.4. =Category Listing & Loop

5. =Main Sidebar
6. =Shortcodes
7. =Footer

*/


/**
 * 1. =Resets & Helpers
 * ----------------------------------------------------------------------------
 */


html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em,
img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, hr,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figure, figcaption, hgroup,
menu, footer, header, nav, section, summary, time, mark, audio, video,
button, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	font-size: 100%;
	font-family: inherit;
}

.textwidget {
    margin-top: 5px;
    margin-bottom: 5px;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* be compatible with "input" defaults */
button, input[type="submit"] {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	cursor: pointer;
	-webkit-appearance: none;
}

/* iOS styling reset */
button, input { border-radius: 0; }

button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

/* fix IE scrollbar */
textarea { overflow: auto; }

a img { border: 0; }

.cf:before, .cf:after { content: " "; display: table; }
.cf:after { clear: both; }
.cf { *zoom: 1; } /* old-ie only */

/* reset placeholder color */
::-webkit-input-placeholder { color: inherit; }

:-moz-placeholder {
	color: inherit;
	opacity: 1;
}

::-moz-placeholder {
	color: inherit;
	opacity: 1;
}

:-ms-input-placeholder { color: inherit; }


/**
 * 1.1. =Typography
 * ----------------------------------------------------------------------------
 */

/* baseline 14px -- 1.5x base line-height */
body {
	font: 14px/1.5 "Open Sans", Arial, sans-serif;
	/* Chrome 38 Bug - text-rendering: optimizeLegibility; */
}

p {	margin-bottom: 21px; /* 1.5em */ }

/* base headings */

h1 { font-size: 24px; }
h2 { font-size: 21px; }
h3 { font-size: 18px; }
h4 { font-size: 16px; }
h5 { font-size: 15px; }
h6 { font-size: 14px; }


h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-family: "Roboto Slab", Georgia, serif;
	color: #19232d;
}

::selection {
	background: #e54e53;
	color: #fff;
}

::-moz-selection {
	background: #e54e53;
	color: #fff;
}

img::selection 	{ background: transparent; }
img::-moz-selection	{ background: transparent; }
body { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* link and text helpers */
a {
	color: #19232d;
	text-decoration: none;

	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

a:hover {
	text-decoration: underline;
}

/**
 * 1.2. =Other Elements
 * ----------------------------------------------------------------------------
 */

code, pre {
	padding: 1.857em;
	padding-bottom: 0;
	border-left: 3px solid #d9d9d9;
	border-bottom: 1px solid transparent;
	background: #f2f2f2;
}

p code {
	padding: 1px 5px;
	border: 0;
}

pre {
	display: block;
	word-break: break-all;
	word-wrap: break-word;
}

pre code { white-space: pre-wrap; }

/* default table styling */
table {
	max-width: 100%;
	background-color: transparent;
}

th, td {
	background: #fff;
	padding: 4px 7px;
	border: 1px solid #e1e1e1;
}

th { background: #f2f2f2; }

tr:nth-child(odd) td { background: #fcfcfc; }


table caption {
	padding: 0 0 7px 0;
	width: auto;
	font-weight: 600;
}

/* form styling */
input, textarea, select {
	background: #f9f9f9;
	border: 1px solid #d9d9d9;
	padding: 7px 14px;
	outline: 0;
	font-size: 13px;
	color: #a6a6a6;
	max-width: 100%;
}

textarea {
	padding: 7px 14px;
	width: 100%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

input:focus, textarea:focus, select:focus {
	border-color: #bbb;
	color: #606569;
}

/* fix firefox flicker */
.wp-post-image { -moz-transform: translate3d(0, 0, 0); }

/* image hover effect */
.wp-post-image:hover { opacity: 0.8; }
.ie .wp-post-image:hover { opacity: 1; }

/**
 * 1.3. =Main Helpers
 * ----------------------------------------------------------------------------
 */
.limit-line {
	overflow: hidden;
	white-space: nowrap;
}

.ellipsis { text-overflow: ellipsis; }

/* image replacement - via H5BP */
.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	*text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
}

/* hide only visually, but have it available for screenreaders: H5BP */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.main-color { color: #e54e53; }


/**
 * 2. =Global Structure
 * ----------------------------------------------------------------------------
 */

/* 1128px total - 44px columns - 25px gutters - 12 columns */

body {
	background: #fff;
	color: #606569;
	-webkit-text-size-adjust: 100%; /* Stops Mobile Safari from auto-adjusting font-sizes */
}

.wrap {
	width: 1078px;
	margin: 0 auto;
	padding: 0 25px; /* 25px gutters */
	text-align: left;
}

.boxed .main-wrap {
	background: #fff;
	width: 1128px;
	margin: 0 auto;
	border-top: 0;
	border-bottom: 0;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.12);
}

body.boxed { background: #eee; }

.ie8 .bg-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -999;
}

/**
 * 2.1 =Grid
 * ----------------------------------------------------------------------------
 */

.row {
	min-height: 1px;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	margin-right: -25px;
    margin-left: -25px;
}

.columns { }

.column, .row [class*="col-"] {

  padding-left: 25px;
  padding-right: 25px;

  float: left;
  min-height: 1px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.col-12, .column { width: 100%; }
.col-11 { width: 91.66666666666667%; }
.col-10 { width: 83.33333333333334%; }
.col-9, .column.three-fourth { width: 75%; }
.col-8, .column.two-third { width: 66.66666666666667%; }
.col-7 { width: 58.333333333333336%; }

.col-6, .column.half { width: 50%; }

.col-5 { width: 41.66666666666667%; }
.col-4, .column.one-third { width: 33.333333333333336%; }
.col-3, .column.one-fourth { width: 25%; }
.col-2 { width: 16.666666666666668%; }
.col-1 { width: 8.333333333333334%; }

.column.one-fifth { width: 20%; }
.column.two-fifth { width: 40%; }
.column.three-fifth { width: 60%; }
.column.four-fifth { width: 80%; }

.column.right { text-align: right; }
.column.left { text-align: left; }
.column.center { text-align: center; }

/**
 * 2.2. =Header
 * ----------------------------------------------------------------------------
 */

.top-bar {
	background: #f2f2f2;
	border-top: 4px solid #e54e53;
	border-bottom: 1px solid #e7e7e7;
}


.top-bar.hidden { display: none; }

.top-bar-content {
	padding: 10px 0;
	color: #999;
}

.top-bar-content > div { display: inline; }
.top-bar-content a { color: inherit; }

.top-bar .menu {
	float: left;
	color: #656565;
	overflow: hidden;
}

.top-bar .menu li {
	float: left;
	padding-left: 14px;
}

.top-bar .menu li:first-child { padding-left: 0; }

.top-bar .menu .sub-menu { display: none; }

.top-bar .search {
	float: right;
	margin: -3px 0 -3px 50px;
	position: relative;
}

.top-bar .search .query {
	width: 130px;
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}

.top-bar .search .query:focus { width: 180px; }


.search .query, .searchform input, .searchform #s {
	line-height: 24px;
	height: 24px;
	padding: 1px 8px;
	outline: 0;
	border: 0;
	border-right: 15px solid #e9e9e9;
	font-size: 13px;
	background: #e9e9e9;
	float: left;
	color: #999;
}

.top-bar .search-button {
	background: transparent;
	margin-left: -28px;
	line-height: 26px;
	height: 26px;
	width: 20px;
	outline: 0;
	float: left;
}

.top-bar .search .fa-search {
	color: #bababa;
}

.top-bar .social-icons {
	float: right;
}

.ie8 .top-bar input { max-width: 500px; }

/* Live search */

.live-search-results {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	right: 0;
	z-index: 1500;
	top: 35px;
	width: 305px;
	padding-top: 21px;
	background: #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

	-webkit-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;

	-webkit-transform: scale(0.8);
	transform: scale(0.8);

	-webkit-transform-origin: top right;
	transform-origin: top right;
}

.live-search-results.fade-in {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.live-search-results .posts-list img {
	width: 58px;
	height: auto;
}

.live-search-results .posts-list li {
	padding: 21px;
	border-bottom: 1px solid #d9d9d9;
}

.live-search-results .posts-list .content {
	margin-left: 75px;
	min-height: 0;
	border: 0;
}

.live-search-results .posts-list .content > a {
	color: initial;
	margin-bottom: 0;
}

.live-search-results .posts-list .view-all {
	border-bottom: 0;
	text-align: center;
	background: #f9f9f9;
	padding: 14px;
}

.live-search-results .posts-list .view-all a, .live-search-results .no-results {
	float: none;
	text-transform: uppercase;
	font-size: 12px;
}

.live-search-results .no-results {
	display: block;
	text-align: center;
	padding-bottom: 21px;
}


/* News ticker */

.trending-ticker ul { float: left; }

.trending-ticker li {
	display: none;
	color: #656565;
}

.trending-ticker a {
	max-width: 450px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.3;
	padding-right: 10px;
	margin-top: -2px;
}

.trending-ticker li:first-child { display: block; }

.trending-ticker .heading {
	float: left;
	color: #e54e53;
	text-transform: uppercase;
	margin-right: 50px;
}

/* header */
.main-head { }

	.main-head .title {
		float: left;
		font-size: 37px;
		font-weight: bold;
		letter-spacing: -1px;
		max-width: 100%;
	}

	.main-head .title a {
		text-decoration: none;
		color: #19232d;
	}

	.logo-image {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		height: auto;
	}

	/* vertically center - and right aligned */
	.main-head .right {
		line-height: 135px;
		text-align: right;
	}

	.main-head header {
		min-height: 135px;
		line-height: 135px;
		overflow: hidden;
	}

	.main-head .adwrap-widget {
		width: 870px;
		width: 870px;
		max-width: 100%;
		margin: 0 auto;
		vertical-align: middle;
		display: inline-block;
		line-height: 1;
		text-align: right;
	}

.main-head .centered {
	text-align: center;
}

	.main-head .centered .title {
		float: none;
	}

/**
 * 2.3. =Navigation
 * ----------------------------------------------------------------------------
 */
.navigation {
	background-color: #821A88;
	border-bottom: 2px solid #821A88;
	color: #efefef;

	text-transform: uppercase;

	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.navigation.sticky {
	position: fixed;
	top: 0;
	z-index: 101;
	width: 1078px;
	background-color: rgba(25, 35, 45, 0.9);

	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;

	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

.admin-bar .navigation.sticky { top: 32px; }

.navigation .mobile, .navigation .off-canvas {
	display: none;
}

.navigation a { color: #efefef; }

.navigation .mobile, .navigation .mobile-menu { display: none; }
.navigation .menu { }

.navigation ul {
	list-style: none;
	line-height: 2;
	position: relative;
}

.navigation ul li {
	float: left;
}

/* nth level link */
.navigation .menu li > a {
	float: left;
	padding: 0px 22px;
}

/* top level links - 45px total height */
.navigation .menu > li > a {
	text-decoration: none;
	line-height: 43px;

	border-bottom: 2px solid transparent;

	margin-bottom: -2px; /* parent border offset */

	/*border-left: 1px solid #141b23;
	box-shadow: -1px 0px 0px 0px #2e4052;
	-webkit-box-shadow: -1px 0px 0px 0px #2e4052;*/

	/*border-left: 1px solid #2e4052;*/
}

/* blips for parent menus */
.navigation .menu li > a:after {
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 0.9;
	content: "\f107";
	text-shadow: 0px 0px 1px #efefef;
	margin-left: 10px;
}

.navigation .menu li li > a:after { display: none; }
.navigation .menu li > a:only-child:after { display: none; }

.navigation .menu > li:first-child > a {
	border-left: 0;
	box-shadow: none;
}

.navigation .menu > li:hover > a,
.navigation .menu > .current-menu-item > a,
.navigation .menu > .current-menu-parent > a,
.navigation .menu > .current-menu-ancestor > a
{
	border-bottom: 2px solid #e54e53;
}

.navigation .menu > li:hover {
	background: #1e2935;
}

/* login */
.menu .user-login { float: right; }

.navigation .menu .user-login a {
	float: right;
	background: #2A3746;
	padding: 0 21px;
	min-width: 12px;
}

.navigation .user-login .text { display: none; }

/* nested menus */

	/* 1st level */
	.navigation .menu > li > ul {
		top: 45px;
		border-radius: 0;
		border: 0;
	}

	/* 1st to nth level links */
	.navigation .menu > li li a {
		float: none;
		display: block;
		text-decoration: none;
		font-size: 13px;
		padding: 6px 25px;
		border-top: 1px solid #1f2c38;
		text-transform: none;

		/* prevent visibility:hidden delays */
		-webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;

		/* box-shadow: 0px -1px 0px 0px #141b23;
		-webkit-box-shadow: 0px -1px 0px 0px #141b23; */
		/* width: 100%;  - variable width disabled */
	}

	.navigation .menu ul li {
		float: none;
		min-width: 220px;
		border-top: 0;
		padding: 0 0;
		position: relative;
	}

	.navigation .menu > li li:first-child a {
		border-top: 0px;
		box-shadow: none;
		-webkit-box-shadow: none;
	}

	.navigation .menu ul {
		position: absolute;
		overflow: visible;
		visibility: hidden;
		display: block;
		opacity: 0;

		padding: 5px 0px;
		background: #19232d;

		-webkit-transition: all .2s ease-in;
		-moz-transition: all .2s ease-in;
		-ms-transition: all .2s ease-in;
		transition: all .2s ease-in;

		z-index: 100;
	}

	/* 2nd+ level */
	.navigation .menu ul ul {
		left: 100%;
		top: 0;
	}

	.navigation li:hover > ul {
		visibility: visible;
		opacity: 1;
	}

	/* nth level hover or current active item */
	.navigation .menu li li:hover, .navigation .menu li li.current-menu-item {
		background: #1e2935;
	}

	/* Mega Menu */

	.navigation li:hover .mega-menu {
		visibility: visible;
		opacity: 1;

		/* z-index increase on hover - prevent transitions making menu re-appear on random hover */
		z-index: 1000;
	}

	.navigation .mega-menu {
		width: 100%;
		left: 0;
		top: 45px;

		margin: 0;

		position: absolute;
		overflow: hidden;
		visibility: hidden;
		display: block;
		opacity: 0;

		padding: 35px 0px; /* 2.5em */

		background: #c8c6c2;

		-webkit-transition: all .2s ease-in;
		-moz-transition: all .2s ease-in;
		-o-transition: all .2s ease-in;
		transition: all .2s ease-in;

		/* fix flicker and use hardware acceleration */
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

		z-index: 100;
	}

	.navigation .mega-menu .sub-menu li, .navigation .menu .mega-menu > li { min-width: 0; }

	/* disable transitions in mega menu */
	.mega-menu .wp-post-image, .mega-menu a {
		-webkit-transition: none;
		-moz-transition: none;
		-o-transition: none;
		transition: none;
	}

	/* sub-nav for category mega menu */

	.mega-menu .sub-cats {
		padding: 0;
		margin-top: -35px;
	}

	.mega-menu .sub-nav {
		padding-right: 50px;
		padding-left: 25px;
		margin-right: 25px;
		background: #1e2935;
		margin-bottom: -600px;
		padding-bottom: 600px;
		padding-top: 37px;
	}

	.mega-menu .sub-nav li {
		float: none;
		display: block;
		position: relative;
	}

	.navigation .mega-menu .sub-nav li a {
		text-transform: uppercase;
		padding: 5px;
		border-top: 0;
		border-bottom: 1px solid #2f4154;
	}

	/* nested sub-nav */
	.mega-menu .sub-nav ul {
		top: 0;
		left: 100%;
	}

	.navigation .mega-menu .sub-nav ul li { min-width: 220px; }
	.navigation .mega-menu .sub-nav ul li:last-child a { border-bottom: 0; }

	.navigation .mega-menu .sub-nav li:hover, .navigation .menu .sub-nav li.current-menu-item  { background: #1e2c3a; }

	.mega-menu .extend { padding: 0 25px 0 0; }

	.mega-menu .heading {
		display: block;
		border-bottom: 1px solid #2f4154;
		padding: 5px 0;
		margin-bottom: 21px;
		font-weight: 600;
	}

	.mega-menu .featured { -webkit-transform: translate3d(0, 0, 0); }

	.mega-menu .featured h2 {
		/*padding-left: 0;*/
		text-transform: none;
	}

	.mega-menu .featured h2 a { color: #19232d; }

	.mega-menu .highlights article { margin-bottom: 14px; }

	.mega-menu .recent-posts .posts-list {
		text-transform: none;
	}

	.mega-menu .posts-list div:first-child { padding-top: 0; }

	.mega-menu .posts-list .post {
		overflow: hidden;
		padding-top: 21px;
	}

	.mega-menu .posts-list .post > a {
		float: left;
		position: relative;
	}

	.mega-menu .posts-list .content {
		border-bottom-color: #2f4154;
		margin-left: 75px;
		min-height: 0;
	}

	.mega-menu .posts-list img { width: 60px; }
	.mega-menu .posts-list .content > a { margin-bottom: 10px; }


	/* mega menu for normal links style */
	.navigation .mega-menu.links {
		padding: 35px 0 42px 0;
	}

	.navigation .mega-menu.links > li.current-menu-item {
		background: inherit;
	}

	.navigation .mega-menu.links > li {
		float: left;
		width: 25%;
		border: 0;
		padding: 0 25px;

		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

		/* when 4 columns are added */
		.navigation .mega-menu.links > li:nth-child(5) { clear:both; }

	/* mega menu headings */
	.navigation .mega-menu.links > li > a {
		border: 0;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		border-bottom: 1px solid #2f4154;
		padding: 5px 0;
	}

	.navigation .mega-menu.links > li > a:after { display: none; }

	.navigation .mega-menu.links > li ul {
		visibility: inherit;
		opacity: 1;
		position: static;
	}

	.navigation .mega-menu.links > li li a {
		padding-left: 10px;
		border-top: 0;
		border-bottom: 1px solid #1f2c38;
	}

	/* caret */
	.navigation .mega-menu.links > li li a:before {
		display: inline-block;
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 0.9;
		content: "\f105";
		margin-right: 10px;
	}

	.navigation .mega-menu.links > li:hover { background: transparent; }

	.mega-menu .wp-post-image:hover { opacity: 1; }


	/* touch devices rules */
	.touch .menu li:hover ul, .touch .navigation li:hover > ul, .touch .navigation li:hover .mega-menu  {
	    opacity: 0;
	    visibility: hidden;
	}

	.touch .menu li.item-active > ul, .touch .navigation li.item-active > ul,
	.touch .navigation li.item-active .mega-menu, .touch .navigation li.item-active .mega-menu > ul,
	.touch .navigation li.item-active .mega-menu.links ul {
		visibility: visible;
		opacity: 1;
	}


/* Centered Nav - Shared */

.nav-center {
	text-align: center;
}

.nav-center ul li {
	display: inline-block;
	float: none;
	vertical-align: top;
}

.nav-center .sub-nav li {
	display: block;
	text-align: center;
}

.nav-center .menu > li .sub-menu li {
	display: block;
}

.nav-center .menu > li ul li, .nav-center .mega-menu, .nav-center .mega-menu .sub-nav li a {
	text-align: left;
}



/* sticky navigation with logo */

.sticky-logo { display: none; }

.sticky.has-logo li > a {
	padding-left: 15px;
	padding-right: 15px;
}

.sticky .sticky-logo {
    font-size: 17px;
    font-weight: 600;
    display: block;
    padding-left: 15px;
    padding-right: 30px;
}

.sticky .sticky-logo img {
	max-height: 43px;
	width: auto;
	display: inline-block;
	vertical-align: middle;
}

.navigation.sticky .sticky-logo a:hover {
	border-color: transparent;
	background: inherit;
}

/* temporarily disable transitions on activation to prevent flicker */
.sticky.no-transition li a {
	-webkit-transition: none;
	-moz-transition: none;
	transition: none;
}


/* breadcrumbs */
.breadcrumbs {
	background: #f2f2f2;
	padding: 14px 25px;
	font-size: 12px;
	color: #8d97a1;
	line-height: 1;
}

.breadcrumbs a {
    color: #656565;
    font-size: 16px;
    margin-right: 10px;
}

.breadcrumbs .active{
    color: #821A88;
    font-size: 18px;
}

.breadcrumbs .delim {
	padding: 0 10px;
	font-size: 16px;
	color: #8d97a1;
	line-height: 0.7;
}

.breadcrumbs .location {
	color: #e54e53;
	margin-right: 21px;
	text-transform: uppercase;
}

/**
 * 2.4. =Featured & Slider
 * ----------------------------------------------------------------------------
 */

/* slider */
.main-featured {
	margin: 28px 0 49px 0; /* 2em 0 3.5em 0 */
	padding: 28px 0;
	background: #f2f2f2;
	border: 1px solid #e7e7e7;
	border-right: 0;
	border-left: 0;
}

.main-featured .row { opacity: 0; }

.main-featured .row.appear {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
}

.main-featured .slides {
	position: relative;
	min-height: 170px;
}

.main-featured img {
	width: auto;
	height: auto;
	max-width: 100%;
}

.main-featured .slider { opacity: 0; }

	.main-featured .wp-post-image { display: block; }

	.main-featured .cat {
		line-height: 30px;
		background: #821A88;

		padding: 0 14px;
		margin-left: 20px;
	}

	.main-featured .slider  h3 {
		position: relative;
		float: left;
	}

	.main-featured .slider h3 a {
		color: #fff;
		font-size: 18px;
		line-height: 2;
	}

	.main-featured .slider .caption, .featured .caption {
		position: absolute;
		bottom: 0;
		background: #19232d;
		background: rgba(25, 35, 45, 0.7);
		width: 100%;
		padding: 14px 20px 17px 20px;

		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}

	.main-featured .pages {
		position: absolute;
		max-width: 100%;
		width: 100%;
		bottom: 0;
		padding-top: 10px; /* allow the mouse pointer a little room */
		z-index: 99;

		left: 0;
		padding: 10px 25px 0px 25px;

		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		overflow: hidden;
	}

	.main-featured .pages a {
		float: left;
		height: 4px;
		width: 19.658119658%;
		background: #72879c;
		margin-left: 0.284900285%;

		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}


	.main-featured .pages:hover a {
		height: 8px;
	}

	.main-featured .pages a:hover {
		opacity: 0.6;
	}

	.main-featured .pages .flex-active {
		background: #e54e53;
	}

	.main-featured .the-date {
		color: #d3dbdf;
		display: block;
		text-transform: uppercase;
		font-size: 11px;
	}

.main-featured .blocks { padding-left: 0; }

	.main-featured .blocks article {
		position: relative;
	}

	.main-featured .blocks .large {
		margin-bottom: 14px;
	}

	.main-featured .blocks h3 {
		position: absolute;
		bottom: 0;
		background: #19232d;
		background: rgba(25, 35, 45, 0.7);
		width: 100%;

		padding: 10px;

		font-size: 13px;
		line-height: 1.5;

		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
	}

	.main-featured .blocks h3 a { color: #fff; }

	.main-featured .blocks .small h3 { padding: 20px 10px 5px 10px; }

	.main-featured .small {
		float: left;
		margin-right: 14px;
	}

	.main-featured .blocks .small.last { margin-right: 0; }

	.main-featured .small img {
		width: 168px;
		height: 137px;
	}

	.ie8 .main-featured .small img { max-width: 168px; }

	/* image zoom effect */
	.main-featured .slides .image-link, .main-featured .blocks .image-link {
		display: block;
		overflow: hidden;

		-webkit-transform: translate3d(0, 0, 0);
	}

	.main-featured .slides img, .main-featured .blocks .image-link img {
		-webkit-transition: all 0.6s;
		-moz-transition: all 0.6s;
		-o-transition: all 0.6s;
		transition: all 0.6s;
	}

	.main-featured .blocks article:hover img, .main-featured .slides .flex-active-slide:hover img {

		/* fix flicker and use hardware acceleration */
		-webkit-transform: translate3d(0, 0, 0);
		-webkit-perspective: 0;
		-webkit-backface-visibility: hidden;

		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-moz-transform: scale(1.1) rotate(0.1deg);  /* firefox flicker fix */

		opacity: 0.9;
	}

	.main-featured .large .cat { margin-left: 7px; }

	.main-featured .slider .caption, .main-featured .blocks h3, .featured .caption {

		/* IE 9 */
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC44Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);

		background: -webkit-linear-gradient(top,  rgba(25,35,45,0) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10+, Safari5.1+ */
		background: linear-gradient(to bottom,  rgba(25,35,45,0) 0%,rgba(0,0,0,0.8) 100%);

		text-shadow: 0px 1px 1px #000;
	}

	.main-featured .flexslider .slides > li { position: relative; }

	.ie8 .main-featured .slider .caption, .ie8 .main-featured .blocks h3 { background-image: url('images/ie8-slider-overlay.png'); }
	.ie8 .main-featured .blocks .small h3 { padding-top: 10px; }


/* -- Flex-slider Styles */

.flex-container a:active, .flexslider a:active, .flex-container a:focus, .flexslider a:focus { outline: none; }
.slides, .flex-control-nav, .flex-direction-nav { margin: 0; padding: 0; list-style: none; z-index: 10; }

.flexslider { margin: 0; padding: 0; }
.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; } /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img { max-width: 100%;  height:auto; }
.flex-pauseplay span { text-transform: capitalize; }

.slides:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }
.no-js .slides > li:first-child { display: block; }

/* FlexSlider Modified */
.flexslider { margin: 0; position: relative; zoom: 1; }
.flex-viewport { max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease; }
.loading .flex-viewport { max-height: 300px; }
.flexslider .slides { zoom: 1; }

/* Direction Nav */
.flex-direction-nav { *height: 0; }
.flex-direction-nav a {
	z-index: 10;
	width: 35px; height: 35px;
	margin: -30px 0 0;
	display: block;
	background: #fff;
	position: absolute;
	top: 50%;
	cursor: pointer;
	font-size: 0;
	opacity: 0;
}

.flex-direction-nav a:after {
	content: "\f105";
	display: block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	line-height: 35px;
	font-size: 30px;
	text-align: center;
	color: #19232d;
}

.flex-direction-nav .flex-prev:after { content: "\f104"; }

.flex-direction-nav a:hover { text-decoration: none; }

.flex-direction-nav .flex-next { right: 44px; }
.flex-direction-nav .flex-prev { left: 44px;}
.flexslider:hover .flex-next { opacity: 0.5;  }
.flexslider:hover .flex-prev { opacity: 0.5; }
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover { opacity: 1; }

/* Control Nav */
.flex-control-nav { width: 100%; position: absolute; bottom: -40px; text-align: center; }
.flex-control-nav li { margin: 0 6px; display: inline-block; zoom: 1; *display: inline; }
.flex-control-paging li a { width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; border-radius: 20px; box-shadow: inset 0 0 3px rgba(0,0,0,0.3); }
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs { margin: 5px 0 0; position: static; overflow: hidden; }
.flex-control-thumbs li { width: 25%; float: left; margin: 0; }
.flex-control-thumbs img { width: 100%; display: block; opacity: .7; cursor: pointer; }
.flex-control-thumbs img:hover { opacity: 1; }
.flex-control-thumbs .flex-active { opacity: 1; cursor: default; }

/* -- End flexslider */

/**
 * 3. =Components & Common Classes
 * ----------------------------------------------------------------------------
 */

 /* pagination */
.main-pagination {
	overflow: hidden;
}

	.main-pagination .page-numbers, .main-pagination a {
		display: block;
		float: left;
		color: #676767;
		padding: 0 10px;
		margin-right: 5px;
		background: #ebebeb;
		line-height: 25px;
		font-size: 13px;
	}

	.main-pagination .current, .main-pagination a:hover {
		color: #fff;
		background: #e54e53;
		text-decoration: none;
	}


.gallery-title, .section-head {
 	background: #f9f9f9;
	border: 1px solid #d9d9d9;
	border-left: 0;
	border-right: 0;

	line-height: 32px;
	color: #19232d;
	font-size: 13px;
	font-family: "Open Sans", Arial, sans-serif;
	font-weight: 600;
	text-transform: uppercase;

	padding: 0 14px;
	margin-bottom: 28px;
}

.gallery-title, .section-head.prominent { color: #e54e53; }

/* post format icons */
.post-format-icon {
	float: right;
	margin-top: -37px;
	background: rgba(255, 255, 255, 0.4);
	width: 25px;
	height: 25px;
	line-height: 25px;
	position: relative;
	text-align: center;
}

.post-format-icon i {
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

/* feedburner form */
.feedburner .heading {
	margin-bottom: 14px;
}

	.feedburner label { display: none; }
	.feedburner input {
		padding: 8px 12px;
		outline: 0;
	}

	.feedburner-email { min-width: 200px; }
	.feedburner-subscribe { margin-left: 4px; }

/* social icons */
.social-icons { }

	.social-icons li {
		float: left;
		margin-right: 5px;
		height: 20px;
	}

	.social-icons li:last-child { margin-right: 0; }

	.social-icons .icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;

		font-size: 12px;
		color: #fff;

		-webkit-transition: all .4s ease;
		-moz-transition: all .4s ease;
		-ms-transition: all .4s ease;
		-o-transition: all .4s ease;
		transition: all .4s ease;
	}

	.social-icons .icon:hover {
		text-decoration: none;
		border-radius: 50%;
	}

	.social-icons .fa-twitter { background: #00abe3; }
	.social-icons .fa-pinterest { background: #ca2128; }
	.social-icons .fa-facebook { background: #004088; }
	.social-icons .fa-linkedin { background: #01669c; }
	.social-icons .fa-rss { background: #ff5b22; }
	.social-icons .fa-google-plus { background: #d3492c; }
	.social-icons .fa-instagram { background: #517fa4; }
	.social-icons .fa-youtube { background: #fe3432; }
	.social-icons .fa-tumblr { background: #44546b; }
	.social-icons .fa-vimeo-square { background: #1ab7ea; }
	.social-icons .fa-stumbleupon { background: #eb4824; }

.cat-title {

	position: absolute;
	top: 0;
	line-height: 25px;
	background: #e54e53;
	display: block;
	font-size: 12px;
	text-transform: uppercase;

	padding: 0 10px;
	margin-left: 7px;

	color: #fff; /* compensate for font weight */

	display: block;
	z-index: 1;
}

.cat-title a { color: inherit; }


hr, hr.separator {
	margin: 41px 0; /* 41+1 = 42 = 3em */
	height: 1px;
	background: #d9d9d9;
}

hr.no-line { background: transparent; }
hr.half { margin-top: 0; }

/* tooltip for header */
.tooltip {
	position: absolute;
	z-index: 1030;
	display: block;
	visibility: visible;
	padding: 5px;
	font-size: 90%;
	opacity: 0;
	line-height: 1.5;
	filter: alpha(opacity=0);
}

.tooltip.in { opacity: 0.8; filter: alpha(opacity=80); }
.tooltip.bottom { margin-top: -2px; }

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	text-transform: none;
	text-decoration: none;
	background-color: #000;
	border-radius: 4px;
}

/* read more button */
.post .read-more { margin-top: 21px; }

.post .read-more a {
	font: inherit;
	display: inline-block;
	padding: 2px 7px;
	background: #821A88;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
}

.post .read-more a:hover { color: #fff; }


/* loading animation */

.ajax-loading {
	margin-bottom: 42px;
}

.spinner {
	margin: 50px auto 0;
	width: 65px;
	text-align: center;
}

.spinner > span {
	width: 16px;
	height: 16px;
	margin-right: 4px;
	background-color: #d6d6d6;

	border-radius: 100%;
	display: inline-block;

	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;

	/* Prevent first frame from flickering when animation starts */
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.spinner span:nth-of-type(3) {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner span:nth-of-type(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0); }
  40% { -webkit-transform: scale(1.0); }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


/* default search widget */
.widget_search { overflow: hidden; }
.widget_search label > span { display: none; }

.widget_search #searchsubmit { margin-left: 5px; }

/* default widgets styling */
.widget { font-size: 13px; }

.widget li {
    border-bottom: 1px solid #d9d9d9;
    padding: 7px 0px;
}

.widget li:first-child { padding-top: 0; }


/* star rating in widgets */
.widget .main-stars {
	margin-bottom: 10px;
}

.widget .main-stars:before {
	content: "\f006\f006\f006\f006\f006";
}

.widget .main-stars:before, .widget .main-stars span:before {
	letter-spacing: 1px;
	margin-right: -1px;
}


/* default recent comments */
.recentcomments  .url {
	color: #e54e53;
	font-style: italic;
}

/* default categories widget */
.widget_categories li {
	overflow: hidden;
	text-align: right;
	font-weight: bold;
}

.widget_categories li a {
	float: left;
	font-weight: normal;
}

.widget_categories .children {
	clear: both;
	padding-top: 7px;
}

.widget_categories .children li {
	border-top: 1px solid #d9d9d9;
	margin-left: 14px;
}

.widget_categories .children li:first-child {
	padding-top: 7px;
	margin-left: 0;
	padding-left: 14px;
}

.widget_categories .children li { border-bottom: 0; }

.widget_categories li ul li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}


/* ads widget */
.adwrap-widget { text-align: center; }

.adwrap-widget img {
	max-width: 100%;
	height: auto;
}

.adwrap-widget > img, .adwrap-widget > div, .adwrap-widget > iframe, .adwrap-widget > ins {
	display: inline-block;
	vertical-align: middle;
}

/* tags cloud */
.tagcloud a {
	padding: 2px 7px;
	border: 1px solid;
	margin: 2px 0.1em;
	display: inline-block;
}


/* rss widget */
.widget_rss .widgettitle a {
	color: inherit;
	display: inline-block;
	vertical-align: middle;
	margin-right: 4px;
}

/* generic posts list (for sidebars, menus, categories etc.) */
.posts-list { }

.posts-list img {
	float: left;
	width: 85px;
	height: auto;
}

.posts-list .content {
	margin-left: 103px;
	line-height: 1;
	min-height: 73px;
	border-bottom: 1px solid #d9d9d9;
}

.posts-list li:first-child {
	padding-top: 0;
}

.posts-list li {
	overflow: hidden;
	padding: 24px 0 0 0;
	border-bottom: 0;
}

.posts-list li > a {
	float: left;
	position: relative;
	/* firefox flicker bug */
	-moz-transform: translate3d(0, 0, 0);
}

.rate-number {
	font-size: 11px;
	background: #19232d;
	position: absolute;
	height: 25px;
	line-height: 25px;
	text-align: center;
	min-width: 25px;

	font-weight: bold;
	color: #efefef;

	right: 0;
	bottom: 0;
}

.rate-number span {
	margin-top: -25px;
	display: block;
}

.rate-number .progress {
	background: #e54e53;
	height: 100%;
	width: 0;
	margin-top: 0;
}

.posts-list .content > a {
	display: block;
	font-size: 13px;
	line-height: 1.615;
	margin: 8px 0;
	font-family: "Roboto Slab", Georgia, serif;
}

.posts-list .content time {
	color: #a6a6a6;
	font-size: 11px;
	text-transform: uppercase;
}

.posts-list .content .comments {
	float: right;
	color: #a6a6a6;
	font-size: 11px;
	white-space: nowrap;
}

.posts-list .comments a { color: inherit; }

.posts-list .comments a:hover { text-decoration: none; }

.posts-list .content .fa {
	font-size: 13px;
}

.posts-list .review {
	margin-top: 0.25em;
	display: block;
}

/* default WordPress classes */
.alignnone { margin: 5px 20px 20px 0; }

.aligncenter, div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright, a img.alignright{
    float: right;
    margin: 0 0 21px 21px;
}

.alignleft, a img.alignleft {
    float: left;
    margin: 0 21px 0 0;
}

.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

a img.alignnone {
    margin: 0 21px 21px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption-text {
	font-style: italic;
	font-size: 0.923em;
	text-align: center;
}

/* calendar widget */
.widget_calendar #wp-calendar {
	width: 100%;
	text-align: center;
}

.widget_calendar caption, .widget_calendar td, .widget_calendar th { text-align: center; }

/* image load animations */
.no-display {
	will-change: opacity;
	opacity: 0;
}

.appear {
	opacity: 1;

	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}


/* Login Modal & bbPress Login Widget */

.bbp-login-form .fa {
	line-height: 35px;
	border: 1px solid #d9d9d9;
	border-right: 0;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	color: #a6a6a6;
	font-size: 14px;
	background: #eee;
	text-align: center;

	display: table-cell;
	vertical-align: middle;

	width: 1%;
	padding: 0 12px;

	min-width: 40px;
}

.bbp-login-form .input-group {
	display: table;
	width: 100%;
	margin-top: 14px;
}

.bbp-login-form.widget-login .input-group input {
	border-left: 0;
	width: 100%;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	line-height: normal;
	padding: 9px 14px;
	display: table-cell;
}

.bbp-login-form.widget-login .input-group input:focus { border-color: #d9d9d9; }

.bbp-login-form .bbp-submit-wrapper {
	float: none;
	text-align: left;
	overflow: hidden;
	margin-top: 21px;
}

.bbp-login-form .bbp-lostpass-link, .register-now .password-msg {
	line-height: 28px;
	color: #a6a6a6;
}

.bbp-register-info {
	margin-top: 21px;
	border-top: 1px solid #d9d9d9;
	padding-top: 14px;
	background: #f9f9f9;
	text-align: center;
	padding: 10px;
}

.bbp-register-info > a { margin-left: 5px; }
.bbp-submit-wrapper .button {
	float: right;
	text-transform: uppercase;
}

.bbp-logged-in .button {
	text-transform: uppercase;
	margin-top: 21px;
}

.bbp-logged-in .content { margin-left: 75px; }
.bbp-logged-in .links { margin-top: 21px; }
.bbp-logged-in .links a { display: block; }
.bbp-logged-in .links .fa {
	color: #a6a6a6;
	font-size: 12px;
	margin-right: 5px;
}

/* register/login modal */

.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000000;
}

.modal-backdrop.fade { opacity: 0; }

.modal-backdrop, .modal-backdrop.fade.in {
	opacity: 0.6;
	filter: alpha(opacity=60);
}

.modal {
	position: fixed;
    margin: 0;
    top: 50%;
    left: 50%;
	z-index: 1050;
	width: 560px;
	margin-left: -280px;
	background-color: #fff;

	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);

	outline: none;
	opacity: 0;
}

.modal.hide { display: none; }

.modal.fade, .modal-backdrop.fade { opacity: 0; }

.modal.fade.in { opacity: 1; }

.modal-header {
	padding: 9px 15px;
	background: #f2f2f2;
}

.modal-header .modal-title {
	font: 14px "Open Sans", Arial, sans-serif;
	text-transform: uppercase;
	border-left: 3px solid #e54e53;
	padding-left: 18px;
	line-height: 24px;
}

.modal-header .close {
	float: right;
	background: transparent;
	padding: 0 5px;
	font-size: 18px;
	font-weight: bold;
}

.modal-body {
	position: relative;
	overflow-y: auto;
	max-height: 400px;
	padding: 15px;
	font-size: 13px;
}

.login-modal .bbp-register-info {
	padding: 21px;
	margin: 21px -15px -15px -15px;
}

.modal-form { margin-bottom: 0; }

.login-modal {
	width: 400px;
	margin-left: -200px;
}

.login-modal .lost-pass, .login-modal .register-now { display: none; }

/* css3 animations and transition helpers */

@-webkit-keyframes fadeIn {
	0% {
	    opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fade-in {
	visibility: visible;
	opacity: 1;
}

.fade-out {
	visibility: hidden;
	opacity: 0;
}


/**
 * 4. =Main Content
 * ----------------------------------------------------------------------------
 */

.main {
	margin: 42px auto; /* 3em */
}

.main > .row, .main .post-wrap > .row {
	overflow: hidden;
	position: relative;
}

.no-sidebar .main-content { width: 100%; }

.main-content { position: relative; }

/**
 * 4.1. =Main Page Blocks
 * ----------------------------------------------------------------------------
 */

/* Highlights Blocks */

.highlights-box {
	margin-bottom: 42px; /* 30 + 12 = 42 (3em) */
}

/* container for smaller 3 columns */
.highlights-box.three-col { margin: 0 -15px 42px -15px; }

.highlights-box.three-col .column.one-third { padding: 0 15px; }

.highlights-box.three-col .highlights .meta { margin-top: -20px; }

.highlights-box .column.half:first-child { border-right: 1px solid #d9d9d9; }

/* main highlights box */
.highlights { position: relative; }

.highlights .image-link {
	min-height: 42px;
	display: block;
	position: relative;
}

.highlights .image-link img {
	display: block;
	width: 100%;
	height: auto;
}

.highlights article { 	position: relative; }

.highlights-box.three-col .post-format-icon { margin-top: -45px; }

.highlights .section-head a { color: inherit; }

.highlights .cat-title.larger {
	line-height: 30px;
	padding: 0 14px;
}

.highlights .image-link .star-rating {
	position: absolute;
	bottom: 12px;
	right: 0;
	background: rgba(255, 255, 255, 0.5);
	padding: 1px 10px;
}

.highlights .image-link .post-format-icon + .star-rating {
    right: 25px;
    padding: 2px 10px;
}

.highlights .meta {
	position: relative;
	margin-top: -12px;
	background: #f2f2f2;
	line-height: 25px;
	font-size: 11px;
	color: #a6a6a6;
	text-transform: uppercase;
	padding-left: 14px;
	overflow: hidden;
}

	.highlights .meta time { float: left; }
	.highlights .meta .comments {
		float: right;
		display: inline-block;
		margin-right: 14px;
		white-space: nowrap;
	}

	.highlights .comments .fa { font-size: 13px; }

	.highlights .comments a { color: inherit; }
	.highlights .comments a:hover { text-decoration: none; }

	.highlights .meta .rate-number {
		float: right;
		background: #19232d;
		position: relative;
		height: 25px;
		text-align: center;
		min-width: 25px;

		font-weight: bold;
		color: #efefef;
	}

	.highlights .rate-number span {
		margin-top: -25px;
		display: block;
	}


	.highlights .rate-number .progress {
		background: #e54e53;
		height: 100%;
		width: 0;
		margin-top: 0;
	}

	.highlights h2 {
		color: #19232d;
		font-size: 14px;
		line-height: 1.3;
		padding-left: 14px;
		margin: 15px 0;
	}


	.highlights h2:before {
		content: " ";
		display: block;
		float: left;
		position: absolute;
		left: 0;
		height: 18px;
		border-left: 3px solid #e54e53;
	}

	.highlights h2 a { color: #19232d; }

.highlights .excerpt {
	font-size: 13px;
	line-height: 1.615;
	margin-top: 14px;
	border-bottom: 1px solid #d9d9d9;
	color: #949697;
	padding-bottom: 14px;

}

.highlights .excerpt p { margin-bottom: 0; }

.block.posts li {
	border-top: 1px solid #d9d9d9;
	padding: 9px 0;  /* 9 + 3 (line-height*size - size / 2) */
	line-height: 1.5;
	overflow: hidden;
}

.block.posts li:first-child { border-top: 0; }

.block.posts li:last-child { padding-bottom: 0; }

.block.posts a {
	color: #19232d;
	font-size: 13px;
	font-family: "Roboto Slab", Georgia, serif;
	margin-left: 14px;
	display: block;
}

.block.posts .fa-angle-right {
	color: #e54e53;
	font-size: 12px;
	float: left;
	line-height: 1.625;
}

.highlights .thumb img {
	width: 60px;
}

.highlights .thumb .content {
	margin-left: 75px;
	min-height: 0;
}

.highlights .content .star-rating {
	margin-top: -5px;
	margin-bottom: 10px;
	display: block;
}

.highlights .thumb li {
	padding-top: 21px;
}

.highlights article { margin-bottom: 0px; }

.highlights .block.posts {
	border-top: 1px solid #d9d9d9;
	margin-top: 12px;
}

.highlights .posts-list .cat-title {
	position: static;
	display: inline-block;
	margin-left: 0;
	font-size: 10px;
	padding: 4px;
	line-height: 1;
}

.highlights .thumb .content > a { margin-bottom: 10px; }

/* News Focus Block */

.news-focus { margin-bottom: 42px; }

.news-focus .heading {
	background: #f9f9f9;
	color: #e54e53;
}

.news-focus .heading > a { color: inherit; }

.news-focus .heading .subcats {
	float: right;
	font-weight: normal;
}

.news-focus .heading li {
	float: left;
	font-size: 12px;
	margin-left: 14px;
	position: relative;
}

.news-focus .heading .subcats a { color: #949697; }
.news-focus .heading .subcats a.active { color: #e54e53; }

/* blips */
.news-focus .heading .active:after, .news-focus .heading .active:before {
	bottom: -1px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
}

.news-focus .heading .active:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 4px;
	left: 50%;
	margin-left: -4px;
}

.news-focus .heading .active:before {
	border-color: rgba(229, 78, 83, 0);
	border-bottom-color: #d9d9d9;
	border-width: 5px;
	left: 50%;
	margin-left: -5px;
}

.news-focus .row {
	overflow: hidden;
	margin-top: 28px;
	display: none;
}

.news-focus .blocks article { margin-top: 28px; }
.news-focus .blocks article:first-child { margin-top: 0; }

.news-focus .news-0 { display: block; }

.news-focus .focus { overflow: hidden; }

.news-focus .focus .image-link {
	float: left;
	margin-right: 14px;
}

.news-focus .meta {
	font-size: 11px;
	text-transform: uppercase;
	color: #a6a6a6;
}

	.news-focus .meta .comments { float: right; }
	.news-focus .meta .comments a { color: #a6a6a6; }
	.news-focus .comments .fa { font-size: 13px; }

.news-focus .focus h2 {
	font-size: 14px;
	padding: 10px 0; /* 10 + 4px (lineheight-font-size/2) = 1em */
}

.news-focus .focus h2 a { color: #19232d; }

.news-focus .excerpt {
	color: #949697;
	padding-bottom: 0;
	border-bottom: 0;
	display: block;
}

.news-focus .excerpt p { margin-bottom: 0; }

.news-focus .posts-list { border-left: 1px solid #d9d9d9; }
.news-focus .posts-list li:first-child { padding-top: 0; }

.news-focus .posts li:first-child { padding-top: 0; }
.news-focus .posts li:last-child { padding-bottom: 0; }

h3.gallery-title {
	font-family: "Open Sans", Arial, sans-serif;
}

.gallery-block { margin-bottom: 42px; }

.gallery-block .carousel li { margin-right: 30px; }
.gallery-block .carousel .title a {
	font-family: "Roboto Slab", serif;
	color: #19232d;
	padding-top: 4px;
	display: block;
}

.gallery-block .carousel .title { margin-bottom: 0; }

.gallery-block .carousel .flex-direction-nav a { top: 70px; }

.gallery-block .rate-number { display: none; }

.gallery-block .carousel .rate-number { display: block; }

.gallery-block .image-link {
	display: block;
	position: relative;
}

.gallery-block img { display: block; }


/* Blog Listing Blocks */

.page-content .row.grid-overlay {
	margin-bottom: 14px; /* 28 + 14 = 42 */
}


/**
 * 4.2. =Single Post
 * ----------------------------------------------------------------------------
 */

.post-header { margin-bottom: 21px; }

.post-header .featured {
	margin-bottom: 14px;
	position: relative;
}

.post-header .featured .caption a {
	color: inherit;
	font-weight: 600;
}

.featured .flex-direction-nav a { right: 21px; }
.featured .flex-direction-nav .flex-prev { left: 21px; }

.no-sidebar .post-header .featured img {
	width: 100%;
	height: auto;
}

/* caption support */
.post-header .featured > a {
	display: block;
	position: relative;
}

.post-header .featured .caption {
	margin-bottom: 0;
	bottom: 5px;
	padding: 14px 20px 14px 20px;
	color: #fff;
	font-size: 14px;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transform: translate3d(0, 0, 0);
}

.post-header .post-title {
	font-size: 20px;
	position: relative;
	padding-left: 18px;
	margin-right: 35px;
	float: left;
}

.gallery-description {
    font-size: 20px;
    position: relative;
    padding-left: 18px;
    margin-right: 35px;
}

.post-header .post-title:before {
	content: " ";
	display: block;
	float: left;
	position: absolute;
	left: 0;
	top: 6px; /* line-height padding */
	height: 20px;
	border-left: 3px solid #e54e53;
}

.post-header .comments {
	float: right;
	margin-left: -35px;
	color: #a6a6a6;
	font-size: 11px;
	line-height: 28px;
}
	.post-header .comments:hover { text-decoration: none; }
	.post-header .comments .fa { font-size: 13px; }


.post-header .post-meta {
	padding: 0;
	font-size: 11px;
	text-transform: uppercase;
	padding: 10px 0;
	border: 1px solid #d9d9d9;
	border-left: 0;
	border-right: 0;
	margin-top: 21px;
	overflow: hidden;
}

/* make html5 videos responsive */
.main-content video {
	width: 100%;
	height: 100%;
	max-width: 100%;
}

.post-meta { color: #a6a6a6; }

.post-meta time { text-transform: uppercase; }

.post-meta .cats { float: right; }

/* variant with title above */
.title-above .post-header .post-meta {
	margin-bottom: 28px;
}

.title-above .post-header .featured {
	margin-bottom: 0;
}

/* post content */
.post-content, .post-share {
	line-height: 1.857;
	font-size: 13px;
	position: relative;
}

	.post-container .post-content { margin-bottom: -1.857em; }

	.post-content a { color: #e54e53; }
	.post-content a:hover { color: #19232d; }

	.post-content p, .post-content > ul, .post-content > div, .post-content > dl, .post-content blockquote, .post-content ol {
		margin-bottom: 1.857em; /* adjust to parent font-size */
	}

	.post .post-content > p:first-child {
		font-size: 1.0769em;
		color: #3a4149;
	}

	/* lists */

	.post-content ul {
		list-style-type: square;
		margin-left: 42px;
	}

	.post-content ol {
		list-style-type: decimal;
		margin-left: 42px;
	}

	.post-content .fa-ul { margin-left: 0; }

	.post-content .fa-ul, .post-content .fa-ul ul { list-style-type: none; }

	.post-content .fa-ul .fa {
		margin-left: -21px;
		width: 2em;
		font-size: 0.9em;
		color: #979797;
	}

	.post-content small { font-size: 0.92308em; }

	/* quotes */
	.post-content blockquote {
		padding: 1.857em;
		padding-bottom: 0;
		border-left: 3px solid #d9d9d9;
		border-bottom: 1px solid transparent;
		background: #f2f2f2;
	}

	.post-content .pullquote {
		float: right;
		font-style: italic;
		font-size: 1.1em;
		padding-left: 14px;
		border-left: 3px solid #d9d9d9;
		max-width: 35%;
	}

	/* fix audio element */
	.mejs-container .mejs-controls > div:last-of-type {
		position: relative;
		clear: both;
		float: right;
		top: -31px;
	}

	/* in-post galleries and images */
	.post-content .tiled-gallery { clear: none; }

	.post-content img, .post-content .wp-caption {
		max-width: 100%;
		height: auto;
	}

	.post-content .gallery-caption {
		font-style: italic;
		font-size: 0.923em;
	}

	.post-content .wp-caption.alignright { margin-left: 31px; }

	.post-content .wp-caption { margin-bottom: 21px; }

	.post-content .wp-caption-text {
    	margin-bottom: 0;
    	margin-right: 10px;
	}

	/* Modern quotes */

	.post-content .modern-quote {
		position: relative;
		padding: 0;
		padding-left: 42px;
		padding-right: 14px;
		padding-top: 17px;
		padding-bottom: 0;

		margin-top: 35px;
		margin-bottom: 35px;


		font-family: "Merriweather", Georgia, serif;
		font-weight: 300;
		font-size: 16px;
		font-style: italic;
		letter-spacing: 0.2px;

		border: 0;
		border-top: 2px solid #e54e53;
		background: transparent;
	}

	.modern-quote:before {
		content: "\201c";
		display: inline-block;
		position: absolute;
		top: 17px;
		left: 0;
		color: #e6e6e6;
		font-size: 60px;
		line-height: 1;
		font-style: normal;
		font-family: Arial, Helvetica, sans-serif;
	}

		.modern-quote p {
			margin-bottom: 21px;
		}

		.modern-quote cite {
			font-size: 12px;
			font-family: "Open Sans", Arial, sans-serif;
			text-transform: uppercase;
			color: #a6a6a6;
		}

		.modern-quote p > cite {
			display: block;
		}

		.modern-quote cite:before {
			content: "\2014";
			margin-right: 3px;
		}

	.modern-quote.alignleft {
		margin-right: 35px;
	}

	.modern-quote.alignright {
		margin-left: 35px;
	}

	.modern-quote.pull {
		margin-bottom: 28px;
		margin-top: 0.6em; /* match top leading, relative to font size */
		padding-right: 0;
		min-width: 250px;
		max-width: 30%;
	}


/* review box */
.review-box {
	background: #f2f2f2;
	border-left: 3px solid #d9d9d9;
	padding: 10px 15px 0 15px;
	font-size: 13px;
}

.post-content > .review-box { margin-bottom: 28px; }

.review-box .heading {
	font-size: 16px;
	font-weight: normal;
	border-left: 3px solid #e54e53;
	margin-left: -18px;
	padding-left: 15px;
	line-height: 2;

	margin-bottom: 20px; /* line-height adds 8px */
}

.review-box .verdict-box {
	overflow: hidden;
	padding: 28px 0;
	border-top: 1px solid #d9d9d9;
	border-bottom: 1px solid #d9d9d9;
}

	.review-box .overall {
		float: left;
		background: #e54e53;
		color: #fff;
		padding: 10px 8px;
		min-width: 115px;
		text-align: center;
	}

	.review-box .overall .number {
		display: block;
		font-size: 65px;
		font-weight: 600;
		line-height: 1;
	}

	.review-box .overall .best { display: none; }

	.review-box .overall .percent { font-size: 25px; }

	.review-box .overall .verdict {
		display: block;
		font-size: 14px;
		text-transform: uppercase;
		margin-top: 10px;
	}

	.review-box .verdict-box .text { margin-left: 150px; }

.review-box ul {
	list-style: none;
	margin-left: 0;
	padding: 28px 0;
}

.review-box li { margin-bottom: 15px; }

	.review-box .label {
		text-transform: uppercase;
	}

	.review-box .criterion {
		overflow: hidden;
		line-height: 2;
	}

	.review-box ul .rating {
		float: right;
		font-weight: 600;
		white-space: nowrap;
	}

	.review-box .rating-bar, .review-box .bar {
		background: #fff;
		height: 21px;
	}

	.review-box .bar {
		background: #e54e53;
		display: block;
	}


/* star rating */

.review-box.stars li:first-child {
	margin-top: -28px;
}

.review-box.stars li {
	border-bottom: 1px solid #d9d9d9;
	padding: 10px 0;
	margin: 0;
}

.review-box .overall-stars {
	display: block;
	height: 15px;
	text-align: center;
}

.review-box .overall-stars .main-stars {
	float: none;
	max-width: 6em;
	margin: 0 auto;
	margin-top: 14px;
	font-size: 15px;
	color: #ec9494;
	color: rgba(255, 255, 255, 0.2);
	text-align: left;
}

.review-box .overall-stars .main-stars span:before { color: #fff; }

.review-box .main-stars {
	float: right;
	font-size: 17px;
	margin-top: 5px;
	display: block;
	padding-right: 1px;
	color: #d9d9d9;
}

.review-box .main-stars:before, .review-box .main-stars span:before {
	margin-right: -4px;
	letter-spacing: 5px;
}

.review-box .main-stars:before { content: "\f005\f005\f005\f005\f005"; }

.ie .review-box .main-stars span:before { width: 6.25em; }

/* main stars for ratings */
.main-stars {
	position: relative;
	font-size: 13px;
	line-height: 1;
	height: 1em;

	color: #e54e53;
	overflow: hidden;

	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
}

.main-stars span {
	color: inherit;
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 2em;
	overflow: hidden;
}

.main-stars:before, .main-stars span:before {
 	content: "\f005\f005\f005\f005\f005";
	color: inherit;
	letter-spacing: 2px;
	margin-right: -2px;

	display: block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main-stars:before { content: "\f006\f006\f006\f006\f006"; }

.main-stars span:before {
	top: 0;
	left: 0;
	position: absolute;
	color: #e54e53;
}

.ie .main-stars span:before { width: 6em; }

/* user ratings */

.user-ratings .votes {
	font-size: 11px;
	letter-spacing: 1px;
	margin-left: 5px;
}

.user-ratings .hover-number {
	letter-spacing: 1px;
	float: right;
	font-weight: 600;
}

.stars .user-ratings .hover-number { margin-right: 21px; }

.user-ratings .main-stars, .user-ratings .rating-bar { cursor: pointer; }

.user-ratings.voted .main-stars, .user-ratings.voted .rating-bar { cursor: default; }


/* post pagination */
.post-pagination { float: right; }
.post-pagination > span {
	display: block;
	float: left;
	padding: 0 10px;
	margin-right: 5px;
	line-height: 25px;
	font-size: 13px;
	color: #fff;
	background: #e54e53;
	text-decoration: none;
}

/* post pagination for post slideshow */
.post-pagination-next {
	overflow: hidden;
	background: #f2f2f2;
}

.post-pagination-next .info {
	font-size: 12px;
	text-transform: uppercase;
}

.post-pagination-next .info strong { padding: 0 2px; }

.post-pagination-next .button {
	padding: 3px 12px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
}

.post-pagination-next .fa {
	padding: 0 3px;
	font-size: 11px;
	display: inline-block;
}

.post-pagination-next .links {
	float: right;
}


/* post sharing */
.post-share {
	border-top: 1px solid #d9d9d9;
	padding: 28px 0;
	margin-top: 28px;
	overflow: hidden;
}

.post-share .text {
	font-size: 18px;
	display: inline-block;
	margin-right: 30px;
	text-transform: uppercase;
	color: #19232d;
}

.anterioara {
    font-size: 16px;
    display: inline-block;
    margin-right: 30px;
    text-transform: uppercase;
    color: #19232d;
}

.urmatoarea {
    float: right;
    height: 30px;
    line-height: 30px;

    text-align: center;
    color: #fff;
    font-size: 20px;

    margin-right: 2px;
}

.share-links { float: right; }

.share-links a {
	width: 78px;
	height: 30px;
	line-height: 30px;

	text-align: center;
	color: #fff;
	font-size: 20px;

	margin-right: 2px;

	background: #19232d;
}

.share-links a:hover { text-decoration: none; }

.share-links .fa-facebook { background: #204385; }
.share-links .fa-twitter { background: #2aa9e0; }
.share-links .fa-google-plus { background: #d3492c; }
.share-links .fa-pinterest { background: #ca2128; }
.share-links .fa-linkedin { background: #02669a; }
.share-links .fa-tumblr { background: #44546b; }

/* author info box */
.author-info {
	font-size: 13px;
	overflow: hidden;
	margin-bottom: 28px;
}

.author-info .avatar { float: left; }

.author-info [rel="author"] {
	text-transform: uppercase;
	color: #19232d;
}

.author-info .social-icons { float: right; }

	.author-info .social-icons li { margin-right: 10px; }

	.author-info .social-icons li a {
		background: #fff;
		color: #606569;
		font-size: 14px;

	}

	.author-info .social-icons li a:hover { color: #19232d; }

.author-info .description {
	margin-left: 135px;
	line-height: 1.714;
}

	.author-info .bio {
		margin-top: 14px;
		margin-bottom: 0;
	}

/* posts navigation in single post */
.navigate-posts {
	border-top: 1px solid #d9d9d9;
	padding: 35px 0;
	overflow: hidden;
	display: table;
	width: 100%;
}

.navigate-posts .previous, .navigate-posts .next {
	display: table-cell;
	width: 50%;
	text-align: right;
	box-sizing: border-box;
	padding: 0 35px;
}

.navigate-posts .title {
	display: block;
	font-size: 11px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.navigate-posts .link { font-family: "Roboto Slab", Georgia, serif; }

.navigate-posts .previous { padding-left: 21px;}

.navigate-posts .next {
	text-align: left;
	border-left: 1px solid #d9d9d9;
	padding-right: 21px;
}

.navigate-posts .previous .fa { margin-right: 7px; }
.navigate-posts .next .fa { margin-left: 7px; }



/* related posts section */
.related-posts, .highlights-box.related-posts {
	margin-bottom: 28px; /* 3em */
}

.related-posts .section-head { color: #e54e53; }

.related-posts ul { overflow: hidden; }
.related-posts li { float: left; }

.related-posts article { position: relative; }
.related-posts .highlights h2 a { font-size: 13px; }

.related-posts .highlights article { margin-bottom: 0; }

/* comments */

.comments .section-head { word-spacing: 4px; }

.comments-list { margin-bottom: 42px; }

.comments-list .comment { margin-top: 28px; }

	.comments-list .comment-meta, .comments-list .comment-content, .comments-list .children {
		margin-left: 75px;
	}

	.comments-list .avatar {  float: left; }

	.comments-list .comment-meta {
		color: #a6a6a6;
		font-size: 12px;
	}

	.comment-meta a, .comment-meta .comment-author {
		text-transform: uppercase;
		color: #606569;
		margin: 0 2px 0 0;
		display: inline-block;
	}

	.comment-meta .comment-edit-link { color: #a6a6a6; }

	.comments-list .bypostauthor .comment-author a { color: #e54e53; }

	.comments-list .comment-content {
		font-size: 13px;
		line-height: 1.714;
		border-bottom: 1px solid #d9d9d9;
		position: relative;
		padding-bottom: 42px;
		margin-top: 14px;
	}

	.comments-list .comment-content p { margin-bottom: 0; }

	.comment-content .reply {
		position: absolute;
		bottom: -1px;
		right: 0;
		color: #a6a6a6;
		font-size: 12px;
		border-bottom: 1px solid #e54e53;
	}

	.comment-content .reply a {
		color: inherit;
		padding: 5px 0;
		text-transform: uppercase;
		display: block;
	}

	.comment-content .reply a:hover { text-decoration: none; }

	.comment-content .reply .fa {
		font-size: 13px;
		color: #b4b4b4;
		margin-left: 5px;
	}

	.comments-list .pingback {
		font-size: 13px;
		margin-left: 75px;
		border-bottom: 1px solid #d9d9d9;
		padding-top: 21px;
	}


/* comment nav */
.comment-nav {
	overflow: hidden;
	font-size: 13px;
	margin: -14px 0 28px 75px; /* 42-14=28px for top */
}

	.comment-nav .nav-previous { float: left; }
	.comment-nav .nav-next { float: right; }
	.comment-nav a { color: #606569; }

/* comment form */
.comment .comment-respond {
	margin-left: 75px;
	margin-top: -1px;
}

.comment-respond form { margin-top: 42px; }

	.comment-respond .section-head { display: block; }

	.comment-respond p {
		margin-bottom: 28px;
		font-size: 13px;
	}

	.comment-respond small {
		float: right;
		margin-top: -55px;
		font-size: 12px;
		font-family: "Open Sans", Arial, sans-serif;
		margin-right: 12px;
	}

	.comment-respond small a { color: #a6a6a6; }


	.comment-respond .form-submit { overflow: hidden; }

	#comment-submit, .submit-button {
		float: right;
		background: #19232d;
		text-transform: uppercase;
		font-size: 14px;
		color: #fff;
		padding: 7px 21px;
	}


/**
 * 4.2.1 =Cover Layout
 * ----------------------------------------------------------------------------
 */

.post-layout-cover .main {
	margin-top: 35px;
}

.post-layout-cover .post-cover {
	margin-bottom: 42px;
	overflow: hidden;
}

.post-cover .featured {
	position: relative;
}

.post-cover .featured img {
	display: block;
	margin-top: -28px;
	max-width: 100%;
	height: auto;
}

.post-cover .featured .hidden {
	visibility: hidden;
}

.post-cover .overlay {
	position: absolute;
	bottom: 0;
	padding: 28px;
	padding-top: 10%;
	width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 5;

	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6000000',GradientType=0 ); /* IE6-9 */
}


.post-cover .overlay .cat-title {
	position: static;
	display: inline-block;
	margin: 0;
	margin-bottom: 21px;
	pointer-events: auto;
}

.post-cover .overlay h1 {
	max-width: 66.667%;
	padding-right: 25px;
	padding-bottom: 28px;

	-moz-box-sizing: border-box;
	box-sizing: border-box;

	font-size: 31px;
	line-height: 1.45;
	color: #fff;

	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
	pointer-events: auto;
}

.post-cover .post-meta {
	color: #eee;
	font-size: 11px;
	font-style: italic;
	padding-left: 3px;
	float: left;

	pointer-events: auto;
}

	.post-cover .posted-by {
		text-transform: lowercase;
	}

	.post-cover .posted-by a, .post-cover .posted-on time, .post-cover .comments a {
		font-style: normal;
		text-transform: uppercase;
		padding: 0 3px;
	}

	.post-cover .posted-by a {
		font-weight: 600;
		color: #fff;
	}

	.post-cover .comments {
		padding-left: 30px;
	}

	.post-cover .comments a {
		color: inherit;
		text-decoration: none;
	}

	.post-cover .comments .fa-comments-o {
		padding-right: 3px;
	}

/* Post cover gallery slider */

.post-cover .gallery-slider + .overlay {
	pointer-events: none;
}

.post-cover .gallery-slider li, .post-cover .featured {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}


/* Post Content overrides */

.post-layout-cover .post-content > p:first-child {
	font-size: inherit;
	color: inherit;
}

.post-layout-cover .post-content {
	padding-left: 3.9%;
	padding-right: 3.9%;
}

.post-layout-cover .post-content .alignleft {
	margin-right: 28px;
	margin-top: 7px;
}

.post-layout-cover .post-content .alignright {
	margin-left: 28px;
	margin-top: 7px;
}

.post-layout-cover .post-content > p img.alignleft {
	margin-left: -3.9%;
}

.post-layout-cover .post-content > p img.alignright {
	margin-right: -3.9%;
}

.post-layout-cover .post-content > p img.alignnone, .post-layout-cover .post-content > p img.aligncenter,
.post-layout-cover .wp-caption.alignnone, .post-layout-cover .wp-caption.aligncenter {
	margin: 35px -3.9%;
	max-width: 107.8%;
}

.post-layout-cover .wp-caption-text {
	color: #a6a6a6;
}

.post-layout-cover .post-content .gallery {
	margin-bottom: 1.857em !important;
}

.post-layout-cover .post-content .gallery, .post-layout-cover .review-box {
	margin-left: -3.9% !important;
	width: 107.8%;
}

.post-layout-cover .gallery-item img {
	border-color: #fff !important;
}

.post-layout-cover .review-box {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}


/**
 * 4.3. =Single & Other Pages
 * ----------------------------------------------------------------------------
 */

.page .post-content { margin-left: 0; }

.page .post-header { margin-bottom: 0; }

.page > .row.builder, .page-content > .main-pagination, .post > .row.builder {
	margin-bottom: 42px;
}

.page > .row.builder .post-content {
	margin-bottom: -1.857em;
}

.page-content .widget-title {
	font-weight: bold;
	color: #19232d;
	margin-bottom: 24px;
	font-family: inherit;
	line-height: 1.5;
}

.page-content .main-heading { font-weight: 600; }

.page .page-content > div:last-child, .page .page-content > section:last-child {
	margin-bottom: 0;
}

/* post/page headings  */
.post-content h1, .post-content h2, .post-content h3,
.post-content h4, .post-content h5, .post-content h6
{
	font-weight: bold;
	color: #19232d;
	margin-bottom: 24px;
	font-family: inherit;
	line-height: 1.5;
}

/* post slideshow */
.post-slideshow {
	margin-top: 14px; /* +21px */
	background: #f7f7f7;
	padding: 1.857em 25px;
}

.post-slideshow h1, .post-slideshow h2, .post-slideshow h3, .post-slideshow h4, .post-slideshow h5, .post-slideshow h6 {
	font-weight: 600;
}

.post-slideshow > p:last-child, .post-slideshow .content-page > p:last-child { margin-bottom: 7px; }

.post-container .post-slideshow {
	margin-bottom: 14px; /* +21px */
}

.post-slideshow .post-pagination-next {
    margin: 0 -25px;
    padding: 14px 25px;
    margin-top: -1.857em;
    margin-bottom: 28px;

    -webkit-box-shadow: 0 1px 0 0 rgba(0,0,0, 0.05);
	box-shadow: 0 1px 0 0 rgba(0,0,0, 0.05);
}

.post-slideshow .content-page {
	margin-bottom: 0;
	opacity: 1;

	-webkit-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
}

.post-slideshow .content-page.hidden { opacity: 0; }

.post-slideshow img.alignnone { margin-bottom: 5px; }


/* author page */
.author .section-head { display: none; }
.author .author-info {
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 42px;
}

/* authors list page */
.authors-list .posts {
	display: block;
	margin-top: 18px;
}

.authors-list .posts a { text-transform: uppercase; }

/* contact us page */
.post-content .wpcf7 { margin-top: 42px; }

.post-content .wpcf7-not-valid-tip {
	position: static;
	margin-left: 10px;
	padding-left: 10px;
	border: 0;
	font-size: 13px;
	border-left: 2px solid #e54e53;
}


/* 404 error */
.error-page { margin-top: 112px; }

.error-page .text-404 {
	font-size: 60px;
	color: #e54e53;
	line-height: 1;
	text-align: left;
	padding-left: 0;
}

.error-page .links, .error-page .fa-ul .fa { margin-left: 0; }

/**
 * 4.4. =Category Listing & Loop
 * ----------------------------------------------------------------------------
 */

.main-heading, .page-title, .entry-title {
	font: 18px "Open Sans", Arial, sans-serif;
	text-transform: uppercase;
	border-left: 3px solid #e54e53;
	padding-left: 14px;
	line-height: 2;
	margin-bottom: 28px;
}

.main-heading strong {
	font-weight: 600;
	margin-left: 14px;
}

.main-heading.prominent { font-weight: 600; }

/* default loop/category style */
.listing-alt article {
	padding: 28px 0;
	overflow: hidden;
	border-top: 1px solid #d9d9d9;
	position: relative;
}

.listing-alt .cat-title {
	top: 28px;
	z-index: 1;
}

.listing-alt article:first-child .cat-title { top: 0; }

.listing-alt article:first-child {
	padding-top: 0;
	border-top: 0;
}

.listing-alt article > a {
	position: relative;
	float: left;
	max-width: 295px;
	overflow: hidden;
}

/* sticky listings */
.listing-alt .sticky {
	background: #f2f2f2;
	padding-right: 25px;
}

.listing-alt .sticky:first-child { padding-top: 28px; }

.listing-alt img {
	width: auto;
	height: auto;
	margin-left: -10%; /* faux crop - save resources */
	-webkit-transform: translate3d(0, 0, 0);
}

.listing-alt article .content {
	margin-left: 325px;
	border-bottom: 0;
	line-height: 1.5;
	margin-top: -5px; /* cut out the line-height extra */
}

.listing-alt .content > a { font-size: 14px; }

.listing-alt .content .excerpt {
	margin-top: 14px;
	font-size: 13px;
	line-height: 1.714;
	color: #949697;
}

.listing-alt .content .excerpt p { margin-bottom: 0; }

.listing-alt .content .read-more {
	margin-top: 18px;
}

.listing-alt + .main-pagination { margin-top: 28px; }


/* Main listing - grid */

.listing { overflow: hidden; }

.listing article { margin-bottom: 28px; }

.listing .sticky {
	background: #f2f2f2;
	padding: 15px;
}

.listing > .column:nth-child(odd) {
	clear: both;
}

.listing .excerpt {
	font-size: 13px;
	line-height: 1.714;
	margin-top: 14px;
	border-bottom: 0;
	padding-bottom: 0;
}

.listing + .main-pagination { margin-top: 14px; }

/* 3 posts grid */

.listing.grid-3 > .column:nth-child(odd) {
	clear: none;
}

.listing.grid-3 > .column:nth-child(3n+1) {
	clear: both;
}

.right-sidebar .listing.grid-3 .column {
	padding-left: 15px;
	padding-right: 15px;
}

.right-sidebar .listing.grid-3 {
	padding-left: 10px;
	padding-right: 10px;
}

/* Grid Overlay style */

.listing.grid-overlay {
	margin-left: -13px;
	margin-right: -13px;
}

.listing.grid-overlay .column {
	padding-left: 13px;
	padding-right: 13px;
}

.right-sidebar .grid-overlay.grid-3 {
	margin-left: -1px;
	margin-right: -1px;
	margin-bottom: 28px;
	padding: 0;
}

.right-sidebar .grid-overlay.grid-3 .column {
	padding-left: 1px;
	padding-right: 1px;
}

.right-sidebar .grid-overlay.grid-3 article {
	margin-bottom: 2px;
}

.right-sidebar .grid-overlay.grid-3 h2 {
	padding-bottom: 7px;
	font-size: 13px;
}

.right-sidebar .grid-overlay.grid-3 .meta {
	display: none;
}

.listing.grid-overlay article {
	margin-bottom: 26px;
}

.listing.grid-overlay .overlay {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding-top: 10%;
	pointer-events: none;

	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */

	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}

.listing.grid-overlay article .image-link {
	display: block;
	height: 0;
	min-height: 0;
	padding-bottom: 62.682215743%;
	overflow: hidden;
}

.listing.grid-overlay h2 {
	margin: 0;
	margin-bottom: 3px;
	padding-bottom: 14px;
	padding-right: 14px;
}

.listing.grid-overlay h2:before {
	display: none;
}

.listing.grid-overlay h2 a {
	pointer-events: auto;
	color: #fff;
}

.listing.grid-overlay .meta {
	position: static;
	background: transparent;
	color: #fff;
	margin-bottom: 0px;
	display: inline-block;
}

.grid-overlay .meta time {
	float: none;
	display: inline-block;
	margin-bottom: 2px;
	color: #efefef;
}


/* Timeline style */

.list-timeline {
	margin-top: 42px;
	border-left: 1px solid #d9d9d9;
}

.list-timeline .month {
	margin-left: 25px;
	position: relative;
	margin-bottom: 42px;
	color: #19232d;
}

.list-timeline .heading {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	float: left;
	padding: 0 15px;
	line-height: 28px;
	border: 1px solid #d9d9d9;
	margin-top: -14px;
	margin-bottom: 28px;
}

.list-timeline .heading:before {
	display: block;
	position: absolute;
	top: 0px;
	left: -25px;
	width: 25px;
	height: 1px;
	background: #d9d9d9;
	content: " ";
}

.list-timeline .posts { clear: both; }

	.list-timeline .posts article {
		font-size: 13px;
		line-height: 1.714;
		margin-bottom: 7px;
		font-family: "Roboto Slab", Georgia, serif;
	}

	.list-timeline .posts time {
		text-transform: uppercase;
		padding-top: 1px;
		padding-right: 42px;
		color: #606569;
		min-width: 40px;
		display: block;
		float: left;
	}

	.list-timeline .posts article a {
		display: block;
		overflow: hidden;
	}



/* Classic large blog listing */

.listing-classic > .post {
	padding-bottom: 35px;
	margin-bottom: 42px;
	border-bottom: 1px solid #d9d9d9;
}

.listing-classic .read-more { margin-top: 28px; }

.listing-classic .post-content > p:first-child {
	font-size: inherit;
	color: inherit;
}

.listing-classic .post-share { padding-bottom: 0; }

.listing-classic .post-header { margin-bottom: 21px; }

.listing-classic > .post:last-child {
	border: 0;
	padding-bottom: 0;
}

.listing-classic + .main-pagination {
	border-top: 1px solid #d9d9d9;
	padding-top: 35px;
}

/* Sidebar Listing use */

.main .sidebar .listing .column {
	width: 100%;
}

.main .sidebar .listing {
	margin-bottom: -28px;
}


/**
 * 4. =Main Sidebar
 * ----------------------------------------------------------------------------
 */
.main .sidebar {
	border-left: 1px solid transparent;
}

.main .sidebar:before, .sidebar .theiaStickySidebar:before {
	content: " ";
	position: absolute;
	border-left: 1px solid #d9d9d9;
	height: 100%;
	margin-left: -25px;
}

.main .sidebar .widget { margin-bottom: 35px; /* 2.5em */ }

.main .sidebar .widgettitle, .tabbed .tabs-list {
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 13px;
	text-transform: uppercase;
	background: #19232d;
	color: #efefef;

	padding: 0 14px;
	line-height: 34px;
	margin-bottom: 35px;
}

	/* tabbed widgets - recent, category1... */
	 .tabbed .tabs-list {
		overflow: hidden;
		padding: 0;
	}

	.tabbed .tabs-list li {
		line-height: 1.5;
		border-bottom: 0;
		padding: 0;
	}

	.tabbed .tabs-list .active a {
		border-bottom: 2px solid #e54e53;
	}

	.tabbed .tabs-list a {
		color: #efefef;
		float: left;
		display: block;
		padding: 0 14px;
		border-bottom: 2px solid transparent;
		line-height: 32px;
	}

	.tabbed .tabs-list a:hover { text-decoration: none; }

	.tabbed .tabs-data { border-bottom: 0; }

	.tabbed .tabs-data > ul:first-child { display: block; }
	.tabbed .tabs-data > .inactive { display: none; }

	.tabbed .posts-list { display: none; }
	.tabbed .posts-list li { }
	.tabbed .posts-list.active, .tabbed .posts-list .posts-list { display: block; }

	/* latest posts and popular posts widget */
	.sidebar .posts-list li { line-height: 1.5; }

	/* sidebar galleries */
	.gallery-block .slider .title { display: none; }

	.gallery-block .slider li {
		padding: 0;
		border: 0;
	}

	.gallery-block .flex-direction-nav a {
		width: 25px;
		height: 25px;
		margin: -10px 0 0;
	}

	.gallery-block .flex-direction-nav a:after {
		line-height: 25px;
		font-size: 25px;
	}

	.gallery-block .flex-direction-nav .flex-next { right: 10px; }
	.gallery-block .flex-direction-nav .flex-prev { left: 10px; }


/**
 * 6. =Shortcodes
 * ----------------------------------------------------------------------------
 */


 /* tabs */
.sc-tabs {
	overflow: hidden;
	margin-bottom: -1px;
}

.post-content .sc-tabs {
	list-style-type: none;
	margin: 0 0 -1px 0;
	clear: both;
}

	.sc-tabs a {
		float: left;
		display: block;
		padding: 5px 28px;
		background: #fcfcfc;
		border: 1px solid #d9d9d9;
		border-left: 0;

		color: #19232d;
		font-size: 13px;
	}

	.sc-tabs .active a {
		background: #f2f2f2;
		border-bottom: 1px solid #e54e53;
	}

	.sc-tabs li:first-child a { border-left: 1px solid #d9d9d9; }

	.sc-tabs a:hover { text-decoration: none; }

	.sc-tabs .active { margin-bottom: -3px; }


.sc-tabs-panes {
	border: 1px solid #d9d9d9;
	padding: 21px;
}

.widget .sc-tabs li {
	border: 0;
	padding: 0;
}

.post-content .sc-tabs-panes { margin-left: 0; }
	.sc-tabs-panes > li { display: none;}
	.sc-tabs-panes > li.active { display: block; }

/* accordions / toggle */
.sc-accordion-pane.active, .sc-toggle-content.active { display: block; }

.sc-accordion-title:first-child, .sc-toggle-title:first-child {
	border-top: 1px solid #d9d9d9;
}

.sc-accordion-title, .sc-toggle-title {
	border: 1px solid #d9d9d9;
	border-top: 0;
}

.sc-accordion-title a, .sc-toggle-title a {
	display: block;
	padding: 10px 21px;
	text-decoration: none;
	position: relative;
}


.sc-accordion-title a:before, .sc-toggle-title a:before {
	content: " ";
	width: 20px;
	height: 20px;
	background: #19232d;

	display: inline-block;
	vertical-align: middle;

	margin-right: 21px;
}


.sc-accordion-title a:after, .sc-toggle-title a:after {
	content: "\f067";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: absolute;
	top: 1.4em;
	left: 21px;
	padding-left: 5px;
	font-size: 13px;
	color: #fff;
	z-index: 1;
}

.sc-accordion-title.active a:after {
	content: "\f068";
}

.sc-accordion-pane, .sc-toggle-content {
	display: none;
	border: 1px solid #d9d9d9;
	border-top: 0;
	padding: 21px;
}

/* buttons */

.sc-button, .button {
	background: #19232d;
	padding: 5px 21px;
	color: #fff;
	display: inline-block;
	font: inherit;
}

.button { background: #e54e53; }
a.button:hover { text-decoration: none; }

.button.smaller {
	font-size: 12px;
	padding: 2px 7px;
	text-transform: uppercase;
}

.post-content .sc-button, .post-content .sc-button:hover { color: #fff; }

.sc-button:hover { text-decoration: none; opacity: 0.9; }

.sc-button-small { font-size: 14px; }
.sc-button-medium {	font-size: 16px; }

.sc-button-large { font-size: 18px; }

.sc-button-red { background: #e54e53; }
.sc-button-orange { background: #ff5b22; }
.sc-button-blue { background: #2a3c4c; }

.sc-button-default:hover {
	background: #e54e53;
}

.drop-caps {
	float: left;
	font-size: 1.9em;
	line-height: 1;
	background: #e54e53;
	border-radius: 50%;
	color: #fff;

	padding: 6px 10px;
	margin-top: 5px;
	margin-right: 14px;
}

.drop-caps.square {	border-radius: 0; }

/**
 * 7. =Footer
 * ----------------------------------------------------------------------------
 */
.main-footer {
 	background: #19232d;
}

.main-footer .wrap {
 	padding: 25px 25px;
}

/* default widget styling */
.main-footer .widget {
	color: #d7dade;
	font-size: 13px;
	line-height: 1.9;
}

.main-footer .widgettitle {
	font: bold 14px/35px "Open Sans", Arial, sans-serif;
	color: #c5c7cb;
	border-bottom: 1px solid #253443;
	text-transform: uppercase;
	margin-bottom: 28px; /* 2em */
}

.main-footer .feedburner input {
	background: #1f2c38;
	color: #c5c7cb;
	border: 0;
	font-size: inherit;
	line-height: 1.4;
}

.main-footer input.feedburner-subscribe {
	background: #2a3c4c;
	padding: 8px 14px;
	font-weight: 600;
}


.lower-foot {
	background: #121a21;
	color: #8d8e92;
	font-size: 13px;
}

	.lower-foot a { color: #b6b7b9; }

	.lower-foot .wrap {
		padding: 14px 25px;
		overflow: hidden;
	}

	.lower-foot .social-icons { float: right; }

	.lower-foot .menu {
		float: right;
		overflow: hidden;
	}

	.lower-foot .textwidget { float: left; }

	.lower-foot .menu li {
		float: left;
		padding-left: 0.5em;
	}

	/* add middot */
	.lower-foot .menu li:before {
		content: "\b7";
		padding-right: 0.5em;
		display: inline-block;
	}

	.lower-foot .menu li:first-child:before { content: ''; }
	.lower-foot .menu .sub-menu { display: none; }

/* footer widgets */
.main-footer .widget a { color: #e0e3E9; }

	/* About Widget */
	.about-widget .logo-text {
		font-size: 22px;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 28px;
	}

	/* Latest posts / other post listing widgets */
	.main-footer .posts-list .content {
		border-bottom-color: #253443;
		min-height: 65px;
		margin-left: 95px;
	}

	.main-footer .posts-list img { width: 75px; }

	.main-footer .posts-list time, .main-footer .posts-list .comments a, .main-footer .posts-list .fa {
		color: #a3a8b1;
	}

	/* Flickr widget */
	.flickr-widget { overflow: hidden; }
	.flickr-widget .flickr_badge_image {
		float: left;
		margin: 0 12px 14px 0px;
	}

	.ie8 .flickr-widget .flickr_badge_image { margin-right: 8px; }

	.flickr-widget .flickr_badge_image:nth-of-type(4n) { margin-right: 0; }

	.flickr-widget a {
		overflow: hidden;
		display: block;
		border-radius: 0px;
		max-height: 64px;
		max-width: 72px;
	}

	.flickr-widget a img {
		float: left;

		max-width: 100%;
		height: auto;

		-webkit-transition: all 0.3s ease-in;
		-moz-transition: all 0.3s ease-in;
		transition: all 0.3s ease-in;
	}

	.flickr-widget img:hover { opacity: 0.8; }
	.ie .flickr-widget img:hover { opacity: 1; }

	/* calendar widget */
	.main-footer .widget_calendar { color: #606569; }
	.main-footer .widget_calendar a { color: #19232d; }
